import { RouteObject } from 'react-router-dom'
import PublicLayout from 'layouts/public.layout'
import PrivateLayout from 'layouts/private.layout'
import Main from 'pages/main'
import Login from 'pages/login'
import Signup from 'pages/signup' // Add this import
import Start from 'pages/start'
import NotFound from 'pages/notFound'
import Profile from 'pages/profile'
import Reset from 'pages/reset'
import { ROLES } from 'utils/auth'
import { withRoleProtection } from 'components/withRoleProtection'
const StartWrapped = withRoleProtection(Start, ROLES.BRAND)

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <PublicLayout />,
    children: [
      {
        index: true,
        element: <Main />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'signup', // Add this route
        element: <Signup />
      },
      {
        path: 'reset',
        element: <Reset />
      }
    ]
  },
  {
    path: '/',
    element: <PrivateLayout />,
    children: [
      {
        path: 'start',
        element: <StartWrapped />
      },
      {
        path: 'profile',
        element: <Profile />
      }
    ]
  },
  {
    path: '*',
    element: <NotFound />
  }
]
