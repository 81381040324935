import { v2Instance } from 'lib/axios'
import { API_CONFIG } from 'config/api'
import type {
  ApiResponse,
  LoginRequest,
  RegisterRequest,
  AuthLoginResponse,
  AuthRegisterResponse
} from 'types/api'

export const authService = {
  async login(data: LoginRequest): Promise<ApiResponse<AuthLoginResponse>> {
    const response = await v2Instance.post<ApiResponse<AuthLoginResponse>>(
      API_CONFIG.AUTH.LOGIN,
      {
        ...data,
        ttl: 1440
      }
    )
    return response.data
  },

  async register(
    data: RegisterRequest
  ): Promise<ApiResponse<AuthRegisterResponse>> {
    const response = await v2Instance.post<ApiResponse<AuthRegisterResponse>>(
      API_CONFIG.AUTH.REGISTER,
      data
    )
    return response.data
  },

  async googleLogin(token: string): Promise<ApiResponse<AuthLoginResponse>> {
    const response = await v2Instance.post<ApiResponse<AuthLoginResponse>>(
      API_CONFIG.AUTH.GOOGLE_LOGIN,
      { access_token: token }
    )
    return response.data
  },

  async validateToken(token: string): Promise<boolean> {
    try {
      await v2Instance.get('/api/v1/auth/me', {
        headers: { Authorization: `Bearer ${token}` }
      })
      return true
    } catch {
      return false
    }
  }
}
