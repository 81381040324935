import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import Wrapper from 'components/wrapper'
import Input from 'components/input'
import Button from 'components/button'
import Logo from 'assets/pngs/login-logo.png'
import GoogleLogin from 'components/GoogleLogin'
import { useAuth } from 'hooks/useAuth'
import { notify } from 'lib/utils'
import LoadingOverlay from 'components/loader/LoadingOverlay'

import { GoogleOAuthProvider } from '@react-oauth/google'
import { API_CONFIG } from 'config/api'

const Login = () => {
  const navigate = useNavigate()
  const { login, loading } = useAuth()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isGoogleLoginVisible, setGoogleLoginVisible] = useState(true)

  const handleLogin = async () => {
    try {
      await login({ email, password })
      navigate('/start')
    } catch (err) {
      console.log(err)
      notify({
        title: 'Error',
        description: 'Login failed',
        icon: 'error',
        showConfirm: true,
        confirmTitle: 'Try Again',
        useTimer: false
      })
    }
  }

  // Effect to unmount GoogleLogin when login button is pressed
  useEffect(() => {
    if (!loading) return

    // When login is loading, hide the GoogleLogin component
    setGoogleLoginVisible(false)

    // Cleanup: If needed, restore GoogleLogin visibility
    return () => {
      setGoogleLoginVisible(true) // This will restore GoogleLogin component if unmounted and re-rendered
    }
  }, [loading])

  return (
    <>
      <LoadingOverlay isVisible={loading} />

      <GoogleOAuthProvider clientId={API_CONFIG.GOOGLE.CLIENT_ID}>
        <Wrapper position="middle">
          <motion.div
            className="box w-full px-5"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 1000,
              type: 'spring',
              stiffness: 260,
              damping: 20
            }}
          >
            <div className="logo mt-12">
              <img
                src={Logo}
                style={{ height: 125 }}
                alt="logo-katchit"
                className="mx-auto"
              />
            </div>
            <div className="form mt-12">
              <div className="mb-3">
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="mb-3">
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <div className="mb-3 font-bold text-[12px] text-[#C72B26] text-center">
                <Link className="text-[#C72B26]" to="/reset">
                  Forgot Password?
                </Link>
              </div>
              <div className="mb-3">
                <Button
                  withShadow={false}
                  variant="primary"
                  onClick={handleLogin}
                  title="Login"
                  disabled={loading}
                />
              </div>
              {isGoogleLoginVisible && (
                <div className="mb-3">
                  <GoogleLogin />
                </div>
              )}
              <div className="font-bold text-[16px] text-center">
                <span className="text-[#BDBDBD]">Don't have account? </span>
                <Link to="/signup">
                  <span className="text-[#C72B26]"> Create Account </span>
                </Link>
              </div>
            </div>
          </motion.div>
        </Wrapper>
      </GoogleOAuthProvider>
    </>
  )
}

export default Login
