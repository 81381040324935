import { useEffect, useRef, useState } from 'react'
import { BrowserMultiFormatReader } from '@zxing/library'
import { useRewardClaims } from 'hooks/useRewardClaims'
import { notify } from 'lib/utils'
import { useAuth } from 'hooks/useAuth'
import LoadingOverlay from 'components/loader/LoadingOverlay'

interface QRScannerProps {
  onClose: () => void
}

const QRScanner = ({ onClose }: QRScannerProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const { claimReward, loading } = useRewardClaims()
  const { user } = useAuth()
  const [scanning, setScanning] = useState(true)

  useEffect(() => {
    const codeReader = new BrowserMultiFormatReader()

    const startScanning = async () => {
      try {
        const videoInputDevices = await codeReader.listVideoInputDevices()

        // Find rear camera
        const rearCamera = videoInputDevices.find(
          (device) =>
            device.label.toLowerCase().includes('back') ||
            device.label.toLowerCase().includes('rear')
        )

        // Use rear camera if found, otherwise fall back to first camera
        const selectedDeviceId = rearCamera
          ? rearCamera.deviceId
          : videoInputDevices[0].deviceId

        if (videoRef.current) {
          await codeReader.decodeFromVideoDevice(
            selectedDeviceId,
            videoRef.current,
            async (result) => {
              if (result && scanning) {
                setScanning(false) // Prevent multiple scans
                try {
                  // Decode base64 QR code value
                  const decodedValue = atob(result.getText())
                  const [claimId, _] = decodedValue.split('|')

                  await claimReward(Number(claimId))
                  notify({
                    title: 'Success',
                    description: 'Reward claimed successfully!',
                    icon: 'success'
                  })
                  onClose()
                } catch (error: any) {
                  console.error('Error processing QR code:', error)
                  notify({
                    title: 'Error',
                    description: error.message || 'Invalid or expired QR code',
                    icon: 'error',
                    showConfirm: true,
                    useTimer: false
                  })
                  setScanning(true) // Re-enable scanning after error
                }
              }
            }
          )
        }
      } catch (error) {
        console.error('Error accessing camera:', error)
        notify({
          title: 'Error',
          description: 'Failed to access camera',
          icon: 'error',
          showConfirm: true,
          useTimer: false
        })
      }
    }

    startScanning()

    return () => {
      codeReader.reset()
    }
  }, [claimReward, onClose, user?.id, scanning])

  return (
    <div className="fixed inset-0 z-50 bg-black bg-opacity-75 flex items-center justify-center">
      <LoadingOverlay isVisible={loading} />
      <div className="bg-white p-4 rounded-lg max-w-md w-full">
        <div className="relative">
          <video
            ref={videoRef}
            className="w-full h-64 object-cover rounded"
          ></video>
          <div className="absolute inset-0 pointer-events-none border-2 border-[#C72B26] rounded"></div>
          <button
            onClick={onClose}
            className="absolute top-2 right-2 text-white bg-[#C72B26] rounded-full p-2 hover:bg-opacity-90"
          >
            ✕
          </button>
        </div>
        <p className="text-center mt-4 text-gray-600">
          Align QR code within the frame
        </p>
      </div>
    </div>
  )
}

export default QRScanner
