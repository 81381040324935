import { scavengerInstance } from 'lib/axios'
import type { ApiResponse } from 'types/api'
import type { QuestRewardClaim, RewardClaim } from 'types/rewards'
import { safePromise } from 'utils/promiseUtils'

export const rewardClaimsService = {
  async getQuestRewardClaims(
    questId: number
  ): Promise<ApiResponse<QuestRewardClaim[]>> {
    return safePromise(
      scavengerInstance
        .get<ApiResponse<QuestRewardClaim[]>>(
          `/api/v1/brand/rewards/quest/${questId}`
        )
        .then((response) => response.data)
    )
  },

  async useRewardClaim(claimId: number): Promise<ApiResponse<RewardClaim>> {
    return safePromise(
      scavengerInstance
        .patch<ApiResponse<RewardClaim>>(
          `/api/v1/brand/rewards/claims/${claimId}/use`
        )
        .then((response) => response.data)
    )
  }
}
