interface ButtonProps {
  title: string
  onClick?: () => void
  withShadow?: boolean
  icon?: string | React.ReactElement
  variant?: 'default' | 'primary' | 'gray'
  loading?: boolean
  disabled?: boolean
}

const Button = ({
  title,
  onClick,
  withShadow = true,
  icon,
  variant = 'default',
  loading = false,
  disabled = false
}: ButtonProps) => {
  const bgColorMap = {
    default: 'bg-white',
    primary: 'bg-primary',
    gray: 'bg-gray'
  }

  const textColorMap = {
    default: 'text-black',
    primary: 'text-white',
    gray: 'text-white'
  }

  const spinnerColorMap = {
    default: 'border-black',
    primary: 'border-white',
    gray: 'border-white'
  }

  return (
    <div>
      <button
        onClick={onClick}
        disabled={disabled || loading}
        className={`
          items-center justify-center flex gap-2 ${
            icon ? 'text-[14px]' : 'text-[18px]'
          } rounded-full
          pe-[15px] py-[10px] ps-[18px] font-semibold w-full
          ${bgColorMap[variant]}
          ${textColorMap[variant]}
          ${
            withShadow
              ? 'shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1),0_2px_4px_-1px_rgba(0,0,0,0.06)]'
              : ''
          }
          ${loading ? 'opacity-75 cursor-not-allowed' : ''}
        `}
      >
        {loading ? (
          <div
            className={`w-5 h-5 border-2 border-t-transparent ${spinnerColorMap[variant]} rounded-full animate-spin`}
          />
        ) : (
          <>
            {icon && (
              <>{typeof icon === 'string' ? <img src={icon} alt="" /> : icon}</>
            )}
            <>{title}</>
          </>
        )}
      </button>
    </div>
  )
}

export default Button
