import { createBrowserRouter } from 'react-router-dom'
import DesktopBlocker from 'components/DesktopBlocker'
import { routes } from './routes'

export const router = createBrowserRouter(
  routes.map((route) => ({
    ...route,
    element: <DesktopBlocker>{route.element}</DesktopBlocker>
  }))
)
