import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { questService } from 'services/quest'

export interface QuestReward {
  id: number
  name: string
  description: string
  type: string
  code: string | null
  image_url: string
  expired_at: string
  is_claimed: boolean
  progress: {
    total_required: number
    captured_required: number
    percentage: number
    required_items_progress: Array<{
      item_id: number
      is_captured: boolean
    }>
  }
}

interface RewardsState {
  rewards: QuestReward[]
  loading: boolean
  error: string | null
}

const initialState: RewardsState = {
  rewards: [],
  loading: false,
  error: null
}

export const fetchQuestRewards = createAsyncThunk(
  'rewards/fetchQuestRewards',
  async (questId: number) => {
    const response = await questService.getQuestRewards(questId)
    return response.data
  }
)

export const claimReward = createAsyncThunk(
  'rewards/claimReward',
  async (data: { reward_id: number }) => {
    const response = await questService.claimReward(data)
    return response.data
  }
)

const rewardsSlice = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    clearRewardsError: (state) => {
      state.error = null
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestRewards.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchQuestRewards.fulfilled, (state, action) => {
        state.rewards = action.payload
        state.loading = false
      })
      .addCase(fetchQuestRewards.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to fetch rewards'
      })
      .addCase(claimReward.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(claimReward.fulfilled, (state, action) => {
        const updatedReward = action.payload
        state.rewards = state.rewards.map((reward) =>
          reward.id === updatedReward.id ? updatedReward : reward
        )
        state.loading = false
      })
      .addCase(claimReward.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'Failed to claim reward'
      })
  }
})

export const { clearRewardsError } = rewardsSlice.actions
export default rewardsSlice.reducer
