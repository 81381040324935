import Loader from '.'

interface LoadingOverlayProps {
  isVisible: boolean
}

const LoadingOverlay = ({ isVisible }: LoadingOverlayProps) => {
  if (!isVisible) return null

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-opacity-50">
      <div className="rounded-lg bg-white p-6">
        <Loader size={80} />
      </div>
    </div>
  )
}

export default LoadingOverlay
