import { useGoogleLogin } from '@react-oauth/google'
import { useNavigate } from 'react-router-dom'
import Button from 'components/button'
import GoogleIcon from 'assets/svgs/google.svg'
import { useAuth } from 'hooks/useAuth'
import { notify } from 'lib/utils'

const GoogleLogin = () => {
  const navigate = useNavigate()
  const { googleLogin, loading } = useAuth()

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        await googleLogin(tokenResponse.access_token)
        notify({
          title: 'Success',
          description: 'Google login successful',
          icon: 'success'
        })
        navigate('/start')
      } catch (error) {
        console.error('Google login error:', error)
        notify({
          title: 'Error',
          description: 'Google login failed',
          icon: 'error',
          showConfirm: true,
          confirmTitle: 'Try Again',
          useTimer: false
        })
      }
    },
    onError: () => {
      notify({
        title: 'Error',
        description: 'Google login failed',
        icon: 'error',
        showConfirm: true,
        confirmTitle: 'Try Again',
        useTimer: false
      })
    }
  })

  return (
    <Button
      withShadow
      icon={GoogleIcon}
      title="Sign in with Google"
      onClick={() => login()}
      loading={loading}
      variant="gray"
    />
  )
}

export default GoogleLogin
