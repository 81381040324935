import { scavengerInstance } from 'lib/axios'
import type { ApiResponse } from 'types/api'
import type { QuestReward } from 'store/slices/rewards'
import { safePromise } from 'utils/promiseUtils'

export const questService = {
  async getQuestRewards(questId: number): Promise<ApiResponse<QuestReward[]>> {
    return safePromise(
      scavengerInstance
        .get<ApiResponse<QuestReward[]>>(`/api/v1/quest/${questId}/rewards`)
        .then((response) => response.data)
    )
  },

  async claimReward(data: {
    reward_id: number
  }): Promise<ApiResponse<QuestReward>> {
    return safePromise(
      scavengerInstance
        .post<ApiResponse<QuestReward>>('/api/v1/quest/rewards/claim', data)
        .then((response) => response.data)
    )
  }
}
