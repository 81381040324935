import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import type { CurrentQuestResponse, EnrollmentRequest } from 'types/enrollment'
import { enrollmentService } from 'services/enrollment'

interface EnrollmentState {
  currentQuest: CurrentQuestResponse | null
  loading: boolean
  error: string | null
}

const initialState: EnrollmentState = {
  currentQuest: null,
  loading: false,
  error: null
}

export const enrollInQuest = createAsyncThunk(
  'enrollment/enroll',
  async (request: EnrollmentRequest, { rejectWithValue }) => {
    try {
      const response = await enrollmentService.enroll(request)
      return response.data
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to enroll in quest'
      )
    }
  }
)
export const selectQuestLocation = (state: { enrollment: EnrollmentState }) => {
  const quest = state.enrollment.currentQuest?.quest
  return quest ? { lat: quest.lat, lng: quest.lng } : null
}

export const selectQuestRadius = (state: { enrollment: EnrollmentState }) => {
  return state.enrollment.currentQuest?.quest.radius ?? 0
}

export const selectQuestMarker = (state: { enrollment: EnrollmentState }) => {
  return state.enrollment.currentQuest?.quest.marker ?? null
}

export const selectQuestBrand = (state: { enrollment: EnrollmentState }) => {
  return state.enrollment.currentQuest?.quest.brand ?? null
}

export const selectRemainingTime = (state: { enrollment: EnrollmentState }) => {
  return state.enrollment.currentQuest?.remainingTime ?? 0
}
export const getCurrentQuest = createAsyncThunk(
  'enrollment/getCurrentQuest',
  async (_, { rejectWithValue }) => {
    try {
      const response = await enrollmentService.getCurrentQuest()
      return response.data
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to fetch current quest'
      )
    }
  }
)

const enrollmentSlice = createSlice({
  name: 'enrollment',
  initialState,
  reducers: {
    clearEnrollmentError: (state) => {
      state.error = null
    },
    clearCurrentQuest: (state) => {
      state.currentQuest = null
    }
  },
  extraReducers: (builder) => {
    // Enroll cases
    builder
      .addCase(enrollInQuest.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        enrollInQuest.fulfilled,
        (state, action: PayloadAction<CurrentQuestResponse>) => {
          state.currentQuest = action.payload
          state.loading = false
          state.error = null
        }
      )
      .addCase(enrollInQuest.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })

    // Get current quest cases
    builder
      .addCase(getCurrentQuest.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        getCurrentQuest.fulfilled,
        (state, action: PayloadAction<CurrentQuestResponse>) => {
          state.currentQuest = action.payload
          state.loading = false
          state.error = null
        }
      )
      .addCase(getCurrentQuest.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
  }
})

export const { clearEnrollmentError, clearCurrentQuest } =
  enrollmentSlice.actions
export default enrollmentSlice.reducer
