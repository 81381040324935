import { Player } from '@lottiefiles/react-lottie-player'
import loaderAnimation from 'assets/lotties/loader.json'

interface LoaderProps {
  size?: number
  className?: string
}

const Loader = ({ size = 100, className = '' }: LoaderProps) => {
  return (
    <div className={`flex items-center justify-center ${className}`}>
      <Player
        autoplay
        loop
        src={loaderAnimation}
        style={{ height: size, width: size }}
      />
    </div>
  )
}

export default Loader
