import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import Wrapper from 'components/wrapper'
import Button from 'components/button'
import Logo from 'assets/pngs/login-logo.png'
import coin from 'assets/pngs/coin.png'
import wallet from 'assets/pngs/wallet.png'
import { useAuth } from 'hooks/useAuth'
import LoadingOverlay from 'components/loader/LoadingOverlay'

const Profile = () => {
  const navigate = useNavigate()
  const { user, loading } = useAuth()

  const ProfileCard = ({
    icon,
    title,
    value,
    onClick
  }: {
    icon: string
    title: string
    value?: number | string
    onClick?: () => void
  }) => (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className="mb-3 w-full min-w-[327px] rounded-[30px]"
      onClick={onClick}
    >
      <div className="w-full rounded-[30px] shadow-[0px_4px_29px_0px_#BDBDBDAA]">
        <div className="flex items-center justify-start h-[100px] px-5 font-bold">
          <div className="flex items-center gap-3">
            <img className="h-[53px] w-[53px]" src={icon} alt={title} />
            <div className="text-[20px]">{title}</div>
          </div>
          {value !== undefined && (
            <div className="pl-12 text-center text-[#C72B26]">{value}</div>
          )}
        </div>
      </div>
    </motion.div>
  )

  return (
    <>
      <LoadingOverlay isVisible={loading} />
      <Wrapper position="middle" direction="col">
        <div className="content flex flex-col gap-y-5">
          <motion.img
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{
              type: 'spring',
              stiffness: 260,
              damping: 20
            }}
            src={Logo}
            style={{ width: 125, height: 125 }}
            className="m-auto mb-2"
            alt="logo-katchit"
          />

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.2 }}
            className="py-5 text-center text-[40px] font-bold text-[#C72B26]"
          >
            {user?.name || 'John Doe'}
          </motion.div>

          {/* {user?.email && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.3 }}
                className="mb-5 text-center text-[18px] font-bold text-[#C72B26]"
              >
                {user.email}
              </motion.div>
            )} */}

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.4 }}
          >
            <ProfileCard icon={coin} title="Snoonu Coins" value={0} />

            <ProfileCard
              icon={wallet}
              title="Wallet"
              onClick={() => navigate('/wallet')}
            />
          </motion.div>
          <div className="action m-auto w-fit min-w-[213px]">
            <Button
              title="Map"
              onClick={() => navigate('/map')}
              variant="primary"
            />
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default Profile
