import { useNavigate } from 'react-router-dom'
import Back from 'assets/pngs/back.png'

interface BackButtonProps {
  path?: string
}

const BackButton: React.FC<BackButtonProps> = ({ path }) => {
  const navigate = useNavigate()
  return (
    <div>
      <button
        onClick={() => (path ? navigate(path) : navigate(-1))}
        className="text-[#C72B26] font-semibold text-[21px] rounded-full p-2 bg-white absolute top-[2vh] left-[2vh]"
      >
        <img src={Back} alt="back-button" />
      </button>
    </div>
  )
}

export default BackButton
