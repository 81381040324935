import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import Wrapper from 'components/wrapper'
import Button from 'components/button'
import Logo from 'assets/pngs/login-logo.png'
const NotFound = () => {
  return (
    <>
      <Wrapper position="middle">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1000,
            type: 'spring',
            stiffness: 260,
            damping: 20
          }}
          className="w-full px-5 text-center"
        >
          <div className="logo mt-12">
            <img
              src={Logo}
              style={{ height: 125 }}
              alt="logo-katchit"
              className="mx-auto"
            />
          </div>
          <div className="mt-12">
            <h1 className="text-4xl font-bold text-primary mb-4">404</h1>
            <p className="text-gray mb-8">Page not found</p>
            <Link to="/">
              <Button
                variant="primary"
                title="Back to Home"
                withShadow={false}
              />
            </Link>
          </div>
        </motion.div>
      </Wrapper>
    </>
  )
}

export default NotFound
