import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Wrapper from 'components/wrapper'
import Logo from '../../assets/pngs/logo.png'
import { AnimatePresence, motion } from 'framer-motion'

const Main = () => {
  const navigate = useNavigate()
  const [isVisible, setIsVisible] = useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false)
    }, 2000)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (!isVisible) {
      const timer = setTimeout(() => {
        navigate('/login')
      }, 300)
      return () => clearTimeout(timer)
    }
  }, [isVisible, navigate])

  return (
    <Wrapper>
      <AnimatePresence initial={true}>
        {isVisible && (
          <motion.div
            className=""
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              type: 'spring',
              restSpeed: 0.5,
              stiffness: 260,
              damping: 20
            }}
          >
            <img src={Logo} alt="logo-katchit" />
          </motion.div>
        )}
        <div className="powered">
          {' '}
          <span className="text-[#BDBDBD]">Powered By</span>{' '}
          <span className="text-[#C72B26] font-extrabold">Katchit</span>
        </div>
      </AnimatePresence>
    </Wrapper>
  )
}

export default Main
