import Envelope from '../../assets/svgs/envelope.svg'
import Password from '../../assets/svgs/password.svg'
import User from '../../assets/svgs/user.svg'

type InputType = 'email' | 'password' | 'text'

interface InputProps {
  type?: InputType
  value: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  className?: string
}

const typeMap: Record<InputType, string> = {
  email: Envelope,
  password: Password,
  text: User
}

const placeHolderMap: Record<InputType, string> = {
  email: 'Email...',
  password: 'Password...',
  text: 'Enter text...'
}

const Input = ({
  type = 'email',
  value,
  onChange,
  placeholder,
  className = ''
}: InputProps) => {
  return (
    <div className={className}>
      <div className="flex gap-5 rounded-[18px] bg-white pe-[15px] py-[18px] ps-[18px] w-full shadow-[0px_4px_4px_0px_#BDBDBDAA] items-center">
        <div className="icon text-[18px]">
          <img src={typeMap[type]} alt={`${type}-icon`} />
        </div>
        <div className="w-full text-start">
          <input
            type={type}
            value={value}
            onChange={onChange}
            placeholder={placeholder ?? placeHolderMap[type]}
            className="outline-0 w-full text-gray-800"
          />
        </div>
      </div>
    </div>
  )
}

export default Input
