import { Navigate, Outlet } from 'react-router-dom'
import { useAppSelector } from 'store'

export default function PublicLayout() {
  const { isAuthenticated } = useAppSelector((state) => state.auth)

  if (isAuthenticated) {
    return <Navigate to="/start" replace />
  }

  return <Outlet />
}
