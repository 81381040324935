import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import type {
  User,
  LoginRequest,
  RegisterRequest,
  AuthLoginResponse,
  AuthRegisterResponse
} from 'types/api'
import { authService } from 'services/auth'

interface AuthState {
  isAuthenticated: boolean
  user: User | null
  token: string | null
  expiresIn: number | null
  loading: boolean
  error: string | null
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  token: null,
  expiresIn: null,
  loading: false,
  error: null
}

export const loginUser = createAsyncThunk(
  'auth/login',
  async (credentials: LoginRequest, { rejectWithValue }) => {
    try {
      const response = await authService.login(credentials)
      return response.data
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Login failed')
    }
  }
)

export const registerUser = createAsyncThunk(
  'auth/register',
  async (userData: RegisterRequest, { rejectWithValue }) => {
    try {
      const response = await authService.register(userData)
      return response.data
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Registration failed'
      )
    }
  }
)

export const googleLoginUser = createAsyncThunk(
  'auth/googleLogin',
  async (token: string, { rejectWithValue }) => {
    try {
      const response = await authService.googleLogin(token)
      return response.data
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Google login failed'
      )
    }
  }
)

export const validateToken = createAsyncThunk(
  'auth/validateToken',
  async (token: string, { rejectWithValue }) => {
    try {
      const isValid = await authService.validateToken(token)
      return isValid
    } catch (error) {
      console.error(error)
      return rejectWithValue('Token validation failed')
    }
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: (state) => {
      state.isAuthenticated = false
      state.user = null
      state.token = null
      state.expiresIn = null
      state.error = null
    },
    clearError: (state) => {
      state.error = null
    }
  },
  extraReducers: (builder) => {
    // Login cases
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        loginUser.fulfilled,
        (state, action: PayloadAction<AuthLoginResponse>) => {
          state.isAuthenticated = true
          state.user = action.payload.user
          state.token = action.payload.access_token
          state.expiresIn = action.payload.expires_in
          state.loading = false
          state.error = null
        }
      )
      .addCase(loginUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })

    // Register cases
    builder
      .addCase(registerUser.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        registerUser.fulfilled,
        (state, action: PayloadAction<AuthRegisterResponse>) => {
          state.isAuthenticated = true
          state.user = action.payload.user
          state.token = action.payload.access_token
          state.loading = false
          state.error = null
        }
      )
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })

    // Token validation cases
    builder
      .addCase(validateToken.fulfilled, (state, action) => {
        state.isAuthenticated = action.payload
        if (!action.payload) {
          state.user = null
          state.token = null
          state.expiresIn = null
        }
      })
      .addCase(validateToken.rejected, (state) => {
        state.isAuthenticated = false
        state.user = null
        state.token = null
        state.expiresIn = null
      })
      .addCase(googleLoginUser.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        googleLoginUser.fulfilled,
        (state, action: PayloadAction<AuthLoginResponse>) => {
          state.isAuthenticated = true
          state.user = action.payload.user
          state.token = action.payload.access_token
          state.expiresIn = action.payload.expires_in
          state.loading = false
          state.error = null
        }
      )
      .addCase(googleLoginUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
  }
})

export const { logout, clearError } = authSlice.actions
export default authSlice.reducer
