import { scavengerInstance } from 'lib/axios'
import type { ApiResponse } from 'types/api'
import { QuestItemDetail } from 'types/scavenger'
import { safePromise } from 'utils/promiseUtils'

// Define the types for the API responses
export interface QuestItem {
  id: number
  quest_id: number
  lat: number
  lng: number
  point: number
  is_captured: boolean
  marker_url: string
  created_at: string
  captured_at?: string
}

export interface CapturesResponse {
  status: number
  completed_at: string | null
  total_point: number
  items: QuestItem[]
}

export interface CaptureItemRequest {
  quest_item_id: number
  lat: number
  lng: number
}

export const scavengerService = {
  async getQuestItems(questId: number): Promise<ApiResponse<QuestItem[]>> {
    return safePromise(
      scavengerInstance
        .get<ApiResponse<QuestItem[]>>(`/api/v1/quest/${questId}/items`)
        .then((response) => response.data)
    )
  },

  async getQuestCaptures(
    questId: number
  ): Promise<ApiResponse<CapturesResponse>> {
    return safePromise(
      scavengerInstance
        .get<ApiResponse<CapturesResponse>>(`/api/v1/quest/${questId}/captures`)
        .then((response) => response.data)
    )
  },

  async captureItem(data: CaptureItemRequest): Promise<ApiResponse<QuestItem>> {
    return safePromise(
      scavengerInstance
        .post<ApiResponse<QuestItem>>('/api/v1/quest/capture', data)
        .then((response) => response.data)
    )
  },

  async getQuestItemDetail(
    itemId: number
  ): Promise<ApiResponse<QuestItemDetail>> {
    return safePromise(
      scavengerInstance
        .get<ApiResponse<QuestItemDetail>>(`/api/v1/quest/items/${itemId}`)
        .then((response) => response.data)
    )
  }
}
