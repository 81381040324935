import { ReactNode } from 'react'

type Position = 'start' | 'middle' | 'end'
type Direction = 'row' | 'col'
interface WrapperProps {
  children: ReactNode
  position?: Position
  fullwidth?: boolean
  direction?: Direction
}

const Wrapper = ({
  children,
  position = 'middle',
  direction = 'col',
  fullwidth = false
}: WrapperProps) => {
  const positionMap = {
    start: 'items-start',
    middle: 'items-center',
    end: 'items-end'
  }
  const directionMap = {
    row: 'flex-row',
    col: 'flex-col'
  }
  return (
    <div
      className={`bg-white max-w-screen-sm m-auto h-[100dvh] flex overflow-hidden ${
        positionMap[position]
      } ${directionMap[direction]} justify-center ${!fullwidth && 'p-5'}`}
    >
      {children}
    </div>
  )
}

export default Wrapper
