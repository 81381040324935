import { Component, ReactNode } from 'react'
import * as Sentry from '@sentry/react'
import Button from 'components/button'

interface Props {
  children: ReactNode
}

interface State {
  hasError: boolean
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true }
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    Sentry.captureException(error, {
      extra: { errorInfo: JSON.stringify(errorInfo) }
    })
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="flex min-h-screen flex-col items-center justify-center">
          <h1 className="text-xl font-bold">Oops, something went wrong!</h1>
          <p className="mt-2 text-gray-600">
            We're working on fixing this issue.
          </p>
          <div className="mt-4">
            <Button
              title="Try Again"
              onClick={() => window.location.reload()}
              variant="primary"
            />
          </div>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
