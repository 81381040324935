import { scavengerInstance } from 'lib/axios'
import type { ApiResponse } from 'types/api'
import type { CurrentQuestResponse, EnrollmentRequest } from 'types/enrollment'
import { safePromise } from 'utils/promiseUtils'

export const enrollmentService = {
  async enroll(
    data: EnrollmentRequest
  ): Promise<ApiResponse<CurrentQuestResponse>> {
    return safePromise(
      scavengerInstance
        .post<ApiResponse<CurrentQuestResponse>>('/api/v1/enrollment', data)
        .then((response) => response.data)
    )
  },

  async getCurrentQuest(): Promise<ApiResponse<CurrentQuestResponse>> {
    return safePromise(
      scavengerInstance
        .get<ApiResponse<CurrentQuestResponse>>(
          '/api/v1/enrollment/current-quest'
        )
        .then((response) => response.data)
    )
  }
}
