import { User, UserRole } from 'types/api'

export const ROLES = {
  BRAND: 'brand' as UserRole,
  PLAYER: 'player' as UserRole,
  ADMIN: 'admin' as UserRole
}

export const hasRole = (user: User | null, role: UserRole): boolean => {
  return user?.role === role
}

export const isBrand = (user: User | null): boolean => {
  return hasRole(user, ROLES.BRAND)
}
