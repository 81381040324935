import Swal, { SweetAlertResult } from 'sweetalert2'

interface GeoPoint {
  lat: number
  lng: number
}

export const detectDistance = (point1: GeoPoint, point2: GeoPoint): number => {
  // Radius of the Earth in meters
  const R = 6371000 // meters

  // Extract latitude and longitude from points
  const lat1 = point1.lat
  const lon1 = point1.lng
  const lat2 = point2.lat
  const lon2 = point2.lng

  // Convert latitude and longitude from degrees to radians
  const lat1Rad = degToRad(lat1)
  const lon1Rad = degToRad(lon1)
  const lat2Rad = degToRad(lat2)
  const lon2Rad = degToRad(lon2)

  // Haversine formula
  const dlon = lon2Rad - lon1Rad
  const dlat = lat2Rad - lat1Rad
  const a =
    Math.sin(dlat / 2) ** 2 +
    Math.cos(lat1Rad) * Math.cos(lat2Rad) * Math.sin(dlon / 2) ** 2
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

  // Calculate the distance
  return R * c
}

// Helper function to convert degrees to radians
const degToRad = (degrees: number): number => {
  return degrees * (Math.PI / 180)
}

interface NotifyOptions {
  title?: string
  description: string
  showConfirm?: boolean
  confirmTitle?: string
  showCancel?: boolean
  cancelTitle?: string
  icon?: 'success' | 'error' | 'warning' | 'info'
  useTimer?: boolean
  allowClose?: boolean
}

export const notify = ({
  title = 'Notification',
  description,
  showConfirm = false,
  confirmTitle = 'OK',
  showCancel = false,
  cancelTitle = 'Cancel',
  icon = 'info',
  useTimer = true,
  allowClose = false
}: NotifyOptions): Promise<SweetAlertResult<void>> => {
  Swal.close()
  return Swal.fire({
    title,
    text: description,
    icon,
    showConfirmButton: showConfirm,
    confirmButtonText: confirmTitle,
    showCancelButton: showCancel,
    cancelButtonText: cancelTitle,
    customClass: {
      popup: 'rounded-[12px] bg-white p-2 max-w-[250px]',
      title: 'text-[14px] font-semibold text-gray-800',
      htmlContainer: 'text-[12px] text-gray-600',
      confirmButton: `
        text-white
        bg-[#C72B26]
        rounded-full
        px-4
        py-2
        text-[12px]
        font-semibold
        hover:bg-opacity-90
        focus:outline-none
      `,
      cancelButton: `
        text-gray-800
        bg-white
        border
        border-gray-300
        rounded-full
        px-4
        py-2
        text-[12px]
        font-semibold
        hover:bg-gray-50
        focus:outline-none
      `,
      actions: 'gap-2'
    },
    buttonsStyling: false,
    allowOutsideClick: allowClose,
    timer: useTimer ? 2000 : undefined,
    timerProgressBar: useTimer,
    showClass: {
      popup: 'animate__animated animate__fadeInUp animate__faster'
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutDown animate__faster'
    }
  })
}

export const loadingIndicator = (): Promise<any> => {
  Swal.close()
  return Swal.fire({
    showConfirmButton: false,
    customClass: {
      htmlContainer: '!p-0',
      popup: 'rounded-2xl max-w-[95px] max-h-[100px]',
      title: 'text-[16px]'
    },
    didOpen: () => {
      Swal.showLoading({} as any)
    },
    allowOutsideClick: false,
    showClass: {
      popup: `
      animate__animated
      animate__fadeInUp
      animate__faster
    `
    },
    hideClass: {
      popup: `
      animate__animated
      animate__fadeOutDown
      animate__faster
    `
    },
    timer: 1500
  })
}
