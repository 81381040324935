import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { RouterProvider } from 'react-router-dom'
import { store, persistor } from './store'
import { router } from './router'
import 'assets/styles/global.css'
import Loader from 'components/loader'
import ErrorBoundary from 'components/ErrorBoundary'
import { initSentry } from './lib/sentry'
const container = document.getElementById('root') as HTMLDivElement
const root = createRoot(container)
initSentry()
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={<Loader />} persistor={persistor}>
        <RouterProvider router={router} />
      </PersistGate>
    </Provider>
  </ErrorBoundary>
)
