import { useEffect } from 'react'
import { useRewardClaims } from 'hooks/useRewardClaims'
import LoadingOverlay from 'components/loader/LoadingOverlay'
import { motion } from 'framer-motion'
import { FiCheck, FiClock } from 'react-icons/fi'
type RewardListProps = {
  refresh?: boolean
}
const RewardList = ({ refresh }: RewardListProps) => {
  const { claims, loading, getQuestRewardClaims } = useRewardClaims()
  const questId = Number(import.meta.env.VITE_QUEST_ID)

  useEffect(() => {
    getQuestRewardClaims(questId)
  }, [getQuestRewardClaims, questId, refresh])

  return (
    <div className="w-full px-4">
      <LoadingOverlay isVisible={loading} />
      <h2 className="text-2xl font-bold text-center mb-6 text-[#C72B26]">
        Recent Claims
      </h2>
      <div className="space-y-4 max-h-[60vh] overflow-y-auto md:max-h-full md:overflow-visible">
        {claims?.length > 0 ? (
          claims?.map((questReward) => (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              key={questReward.reward.id}
              className="bg-white rounded-xl shadow-md overflow-hidden"
            >
              <div className="p-4">
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-lg font-semibold text-[#C72B26]">
                    {questReward.reward.name}
                  </h3>
                  <span className="text-sm text-gray-500">
                    {questReward.claims.length} Claims
                  </span>
                </div>
                <p className="text-gray-600 text-sm mb-4">
                  {questReward.reward.description}
                </p>
                <div className="space-y-3">
                  {questReward.claims.map((claim) => (
                    <div
                      key={claim.id}
                      className="border-t border-gray-100 pt-3 flex items-center justify-between"
                    >
                      <div>
                        <p className="text-sm font-medium">{claim.user.name}</p>
                        <p className="text-xs text-gray-500">
                          {new Date(claim.claimed_at).toLocaleDateString()}
                        </p>
                      </div>
                      <div className="flex items-center">
                        {claim.is_used ? (
                          <span className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
                            <FiCheck className="mr-1" />
                            Used
                          </span>
                        ) : (
                          <span className="inline-flex items-center px-3 py-1 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                            <FiClock className="mr-1" />
                            Pending
                          </span>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          ))
        ) : (
          <div className="text-center py-8">
            <p className="text-gray-500">No reward claims found</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default RewardList
