export const safePromise = <T>(promise: Promise<T>): Promise<T> => {
  return new Promise((resolve, reject) => {
    Promise.resolve(promise)
      .then(resolve)
      .catch((error) => {
        console.error('Promise error:', error)
        reject(error)
      })
  })
}
