import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { rewardClaimsService } from 'services/rewardClaims'
import type { QuestRewardClaim, RewardClaim } from 'types/rewards'
import { notify } from 'lib/utils'
import { AxiosError } from 'axios'

interface RewardClaimsState {
  claims: QuestRewardClaim[]
  selectedClaim: RewardClaim | null
  loading: boolean
  error: string | null
}

const initialState: RewardClaimsState = {
  claims: [],
  selectedClaim: null,
  loading: false,
  error: null
}

export const fetchQuestRewardClaims = createAsyncThunk(
  'rewardClaims/fetchQuestRewardClaims',
  async (questId: number, { rejectWithValue }) => {
    try {
      return await rewardClaimsService.getQuestRewardClaims(questId)
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        return rejectWithValue(
          error.response?.data?.message || 'Failed to fetch reward claims'
        )
      }
      return rejectWithValue('Failed to fetch reward claims')
    }
  }
)

export const claimReward = createAsyncThunk(
  'rewardClaims/useRewardClaim',
  async (claimId: number, { rejectWithValue }) => {
    try {
      const response = await rewardClaimsService.useRewardClaim(claimId)
      notify({
        title: 'Success',
        description: 'Reward claim used successfully!',
        icon: 'success'
      })
      return response
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        notify({
          title: 'Error',
          description:
            error.response?.data?.message || 'Failed to use reward claim',
          icon: 'error'
        })
        return rejectWithValue(
          error.response?.data?.message || 'Failed to use reward claim'
        )
      }
      return rejectWithValue('Failed to use reward claim')
    }
  }
)

const rewardClaimsSlice = createSlice({
  name: 'rewardClaims',
  initialState,
  reducers: {
    setSelectedClaim: (state, action) => {
      state.selectedClaim = action.payload
    },
    clearRewardClaimsError: (state) => {
      state.error = null
    },
    resetRewardClaims: () => initialState
  },
  extraReducers: (builder) => {
    // Handle fetchQuestRewardClaims
    builder
      .addCase(fetchQuestRewardClaims.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(fetchQuestRewardClaims.fulfilled, (state, action) => {
        state.claims = action.payload.data
        state.loading = false
      })
      .addCase(fetchQuestRewardClaims.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })

    // Handle useRewardClaim
    builder
      .addCase(claimReward.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(claimReward.fulfilled, (state, _) => {
        state.loading = false
      })
      .addCase(claimReward.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
  }
})

export const { setSelectedClaim, clearRewardClaimsError, resetRewardClaims } =
  rewardClaimsSlice.actions

export default rewardClaimsSlice.reducer
