import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { UserRole } from 'types/api'
import { hasRole } from 'utils/auth'
import { notify } from 'lib/utils'

export const withRoleProtection = (
  WrappedComponent: React.ComponentType<any>,
  requiredRole: UserRole
) => {
  return function WithRoleProtectionWrapper(props: any) {
    const { user, isAuthenticated, signOut } = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
      if (!isAuthenticated) {
        navigate('/login')
        return
      }

      if (!hasRole(user, requiredRole)) {
        notify({
          title: 'Access Denied',
          description: 'You do not have permission to access this resource',
          icon: 'error',
          showConfirm: true,
          confirmTitle: 'OK',
          useTimer: false
        })
        signOut()
        navigate('/login')
      }
    }, [isAuthenticated, user, navigate, signOut])

    if (!isAuthenticated || !hasRole(user, requiredRole)) {
      return null
    }

    return <WrappedComponent {...props} />
  }
}
