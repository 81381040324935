export const API_CONFIG = {
  V2_BASE_URL: import.meta.env.VITE_API_V2_URL,
  SCAVENGER_BASE_URL: import.meta.env.VITE_API_SCAVENGER_URL,
  AUTH: {
    LOGIN: '/api/v1/auth/login',
    REGISTER: '/api/v1/auth/register',
    GOOGLE_LOGIN: '/api/v1/auth/login/google' // Add this
  },
  GOOGLE: {
    CLIENT_ID:
      '754203987237-sbp4k6ka4ub1ba7jjn884mr63k3uha8b.apps.googleusercontent.com'
  }
}
