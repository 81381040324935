import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import {
  fetchQuestRewardClaims,
  claimReward,
  setSelectedClaim,
  clearRewardClaimsError,
  resetRewardClaims
} from 'store/slices/rewardClaims'

export const useRewardClaims = () => {
  const dispatch = useAppDispatch()
  const { claims, selectedClaim, loading, error } = useAppSelector(
    (state) => state.rewardClaims
  )

  const getQuestRewardClaims = useCallback(
    async (questId: number) => {
      try {
        await dispatch(fetchQuestRewardClaims(questId)).unwrap()
      } catch (error) {
        console.error('Failed to fetch reward claims:', error)
        throw error
      }
    },
    [dispatch]
  )

  const handleUseRewardClaim = useCallback(
    async (claimId: number) => {
      try {
        await dispatch(claimReward(claimId)).unwrap()
      } catch (error) {
        console.error('Failed to use reward claim:', error)
        throw error
      }
    },
    [dispatch]
  )

  const selectClaim = useCallback(
    (claim: any) => {
      dispatch(setSelectedClaim(claim))
    },
    [dispatch]
  )

  const clearError = useCallback(() => {
    dispatch(clearRewardClaimsError())
  }, [dispatch])

  const reset = useCallback(() => {
    dispatch(resetRewardClaims())
  }, [dispatch])

  return {
    claims,
    selectedClaim,
    loading,
    error,
    getQuestRewardClaims,
    claimReward: handleUseRewardClaim,
    selectClaim,
    clearError,
    reset
  }
}
