import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import Wrapper from 'components/wrapper'
import Input from 'components/input'
import Button from 'components/button'
import Logo from 'assets/pngs/login-logo.png'
import { notify } from 'lib/utils'
import BackButton from 'components/backButton'
import LoadingOverlay from 'components/loader/LoadingOverlay'

const Reset = () => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)

  const handleResetPassword = async () => {
    if (!email) {
      notify({
        title: 'Error',
        description: 'Please enter your email address',
        icon: 'error',
        showConfirm: true,
        confirmTitle: 'OK',
        useTimer: false
      })
      return
    }

    try {
      setLoading(true)
      // TODO: Implement actual reset password API call here
      await new Promise((resolve) => setTimeout(resolve, 1000)) // Simulate API call

      notify({
        title: 'Success',
        description: 'Reset password link has been sent to your email',
        icon: 'success'
      })

      // Navigate back to login after success
      setTimeout(() => {
        navigate('/login')
      }, 2000)
    } catch (error) {
      console.error('Reset password error:', error)
      notify({
        title: 'Error',
        description: 'Failed to send reset password link',
        icon: 'error',
        showConfirm: true,
        confirmTitle: 'Try Again',
        useTimer: false
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <LoadingOverlay isVisible={loading} />
      <Wrapper position="middle">
        <BackButton />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1000,
            type: 'spring',
            stiffness: 260,
            damping: 20
          }}
          className="w-full px-5"
        >
          <div className="logo mt-12">
            <img
              src={Logo}
              style={{ height: 125 }}
              alt="logo-katchit"
              className="mx-auto"
            />
          </div>
          <div className="form mt-12">
            <div className="mb-5 text-center text-[36px] font-bold text-[#C72B26]">
              Forgot Password
            </div>
            <div className="mb-5 text-center text-[14px] text-gray-600">
              Enter your email address and we'll send you a link to reset your
              password.
            </div>
            <div className="mb-8">
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
            </div>
            <div>
              <Button
                withShadow={false}
                variant="primary"
                onClick={handleResetPassword}
                title="Send Reset Link"
                loading={loading}
              />
            </div>
          </div>
        </motion.div>
      </Wrapper>
    </>
  )
}

export default Reset
