import axios, {
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig
} from 'axios'
import { API_CONFIG } from 'config/api'
import { store } from 'store'
import { logout } from 'store/slices/auth'

// Create instances for different APIs
export const v2Instance: AxiosInstance = axios.create({
  baseURL: API_CONFIG.V2_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

export const scavengerInstance: AxiosInstance = axios.create({
  baseURL: API_CONFIG.SCAVENGER_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

// Request interceptor
const requestInterceptor = (config: InternalAxiosRequestConfig) => {
  const state = store.getState()
  const token = state.auth.token

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  return config
}

// Response interceptor
const responseInterceptor = {
  onSuccess: (response: any) => response,
  onError: (error: AxiosError) => {
    // Handle 401 Unauthorized
    if (error.response?.status === 401) {
      store.dispatch(logout())
    }

    // Handle 500 Internal Server Error
    if (error.response?.status === 500) {
      // You can dispatch an action to show global error notification
      console.error('Server Error:', error)
    }

    // Handle network errors
    if (!error.response) {
      console.error('Network Error:', error)
    }

    return Promise.reject(error)
  }
}

// Apply interceptors to both instances
;[v2Instance, scavengerInstance].forEach((instance) => {
  instance.interceptors.request.use(requestInterceptor, (error) =>
    Promise.reject(error)
  )

  instance.interceptors.response.use(
    responseInterceptor.onSuccess,
    responseInterceptor.onError
  )
})
