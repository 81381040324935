import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import authReducer from './slices/auth'
import enrollmentReducer from './slices/enrollment'
import scavengerReducer from './slices/scavenger'
import rewardsReducer from './slices/rewards'
import rewardClaimsReducer from './slices/rewardClaims'

// Define root reducer
const rootReducer = combineReducers({
  auth: authReducer,
  enrollment: enrollmentReducer,
  scavenger: scavengerReducer,
  rewards: rewardsReducer,
  rewardClaims: rewardClaimsReducer
})

// Configure persist options
const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth'] // Only auth will be persisted
}

// Create persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
    })
})

export const persistor = persistStore(store)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
export const useAppDispatch = () => useDispatch<AppDispatch>()
