import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import Wrapper from 'components/wrapper'
import Input from 'components/input'
import Button from 'components/button'
import Logo from 'assets/pngs/login-logo.png'
import { useAuth } from 'hooks/useAuth'
import { notify } from 'lib/utils'
import LoadingOverlay from 'components/loader/LoadingOverlay'
import BackButton from 'components/backButton'

const Signup = () => {
  const navigate = useNavigate()
  const { register, loading } = useAuth()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const handleSignup = async () => {
    if (password !== passwordConfirmation) {
      notify({
        title: 'Error',
        description: 'Passwords do not match',
        icon: 'error',
        showConfirm: true,
        confirmTitle: 'OK',
        useTimer: false
      })
      return
    }

    try {
      await register({
        name,
        email,
        phone,
        password,
        password_confirmation: passwordConfirmation
      })

      notify({
        title: 'Success',
        description: 'Registration successful',
        icon: 'success'
      })

      navigate('/start')
    } catch (err) {
      console.error(err)
      notify({
        title: 'Error',
        description: 'Registration failed',
        icon: 'error',
        showConfirm: true,
        confirmTitle: 'Try Again',
        useTimer: false
      })
    }
  }

  return (
    <>
      <LoadingOverlay isVisible={loading} />
      <Wrapper position="middle">
        <BackButton />
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 1000,
            type: 'spring',
            stiffness: 260,
            damping: 20
          }}
          className="w-full px-5"
        >
          <div className="logo mt-12">
            <img
              src={Logo}
              style={{ height: 125 }}
              alt="logo-katchit"
              className="mx-auto"
            />
          </div>
          <div className="form mt-12">
            <div className="mb-3">
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Full Name"
                className="w-full"
              />
            </div>
            <div className="mb-3">
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mb-3">
              <Input
                type="text"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                placeholder="Phone Number"
              />
            </div>
            <div className="mb-3">
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Password"
              />
            </div>
            <div className="mb-3">
              <Input
                type="password"
                value={passwordConfirmation}
                onChange={(e) => setPasswordConfirmation(e.target.value)}
                placeholder="Confirm Password"
              />
            </div>
            <div className="mt-5">
              <Button
                withShadow={false}
                variant="primary"
                onClick={handleSignup}
                title="Create Account"
                loading={loading}
              />
            </div>
          </div>
        </motion.div>
      </Wrapper>
    </>
  )
}

export default Signup
