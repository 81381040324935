import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { scavengerService } from 'services/scavenger'
import type {
  QuestItem,
  CapturesResponse,
  CaptureItemRequest,
  ScavengerState,
  QuestItemDetail
} from 'types/scavenger'
import { notify } from 'lib/utils'

const initialState: ScavengerState = {
  items: [],
  captures: null,
  selectedItemDetail: null,
  selectedItem: null,
  nearestItems: [],
  loading: false,
  error: null
}

// Async thunks
export const fetchQuestItems = createAsyncThunk(
  'scavenger/fetchItems',
  async (questId: number, { rejectWithValue }) => {
    try {
      const response = await scavengerService.getQuestItems(questId)
      return response.data
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to fetch quest items'
      )
    }
  }
)

// Add new thunk for fetching item details
export const fetchQuestItemDetail = createAsyncThunk(
  'scavenger/fetchItemDetail',
  async (itemId: number, { rejectWithValue }) => {
    try {
      const response = await scavengerService.getQuestItemDetail(itemId)
      return response.data
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to fetch item details'
      )
    }
  }
)

export const fetchQuestCaptures = createAsyncThunk(
  'scavenger/fetchCaptures',
  async (questId: number, { rejectWithValue }) => {
    try {
      const response = await scavengerService.getQuestCaptures(questId)
      return response.data
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to fetch captures'
      )
    }
  }
)

export const captureItem = createAsyncThunk(
  'scavenger/captureItem',
  async (data: CaptureItemRequest, { rejectWithValue }) => {
    try {
      const response = await scavengerService.captureItem(data)
      notify({
        title: 'Success',
        description: 'Item captured successfully!',
        icon: 'success'
      })
      return response.data
    } catch (error: any) {
      notify({
        title: 'Error',
        description: error.response?.data?.message || 'Failed to capture item',
        icon: 'error'
      })
      return rejectWithValue(error.response?.data?.message || 'Capture failed')
    }
  }
)

// Create the slice
const scavengerSlice = createSlice({
  name: 'scavenger',
  initialState,
  reducers: {
    setSelectedItem: (state, action: PayloadAction<QuestItem | null>) => {
      state.selectedItem = action.payload
    },
    clearScavengerError: (state) => {
      state.error = null
    },
    updateItemCapture: (state, action: PayloadAction<number>) => {
      const itemId = action.payload
      // Update items array
      const item = state.items.find((i) => i.id === itemId)
      if (item) {
        item.is_captured = true
        item.captured_at = new Date().toISOString()
      }
      // Update nearestItems array
      const nearItem = state.nearestItems.find((i) => i.id === itemId)
      if (nearItem) {
        nearItem.is_captured = true
        nearItem.captured_at = new Date().toISOString()
      }
      // Update captures if they exist
      if (state.captures) {
        state.captures.total_point += item?.point || 0
        state.captures.items.push(item as QuestItem)
      }
    },
    resetScavenger: () => initialState
  },
  extraReducers: (builder) => {
    // Handle fetchQuestItems
    builder
      .addCase(fetchQuestItems.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        fetchQuestItems.fulfilled,
        (state, action: PayloadAction<QuestItem[]>) => {
          state.items = action.payload
          state.loading = false
        }
      )
      .addCase(fetchQuestItems.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })

    // Handle fetchQuestCaptures
    builder
      .addCase(fetchQuestCaptures.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        fetchQuestCaptures.fulfilled,
        (state, action: PayloadAction<CapturesResponse>) => {
          state.captures = action.payload
          state.loading = false
        }
      )
      .addCase(fetchQuestCaptures.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })

    // Handle captureItem
    builder
      .addCase(captureItem.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        captureItem.fulfilled,
        (state, action: PayloadAction<QuestItem>) => {
          const capturedItem = action.payload
          state.loading = false
          // Update the item in both items and nearestItems arrays
          state.items = state.items.map((item) =>
            item.id === capturedItem.id ? capturedItem : item
          )
          state.nearestItems = state.nearestItems.map((item) =>
            item.id === capturedItem.id ? capturedItem : item
          )
          // Update captures
          if (state.captures) {
            state.captures.total_point += capturedItem.point
            state.captures.items.push(capturedItem)
          }
        }
      )
      .addCase(captureItem.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
      })
      .addCase(fetchQuestItemDetail.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(
        fetchQuestItemDetail.fulfilled,
        (state, action: PayloadAction<QuestItemDetail>) => {
          state.selectedItemDetail = action.payload
          state.loading = false
          state.error = null
        }
      )
      .addCase(fetchQuestItemDetail.rejected, (state, action) => {
        state.loading = false
        state.error = action.payload as string
        state.selectedItemDetail = null
      })
  }
})

// Export actions and reducer
export const {
  setSelectedItem,
  clearScavengerError,
  updateItemCapture,
  resetScavenger
} = scavengerSlice.actions

export default scavengerSlice.reducer
