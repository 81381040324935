import { useCallback, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'store'
import {
  loginUser,
  registerUser,
  googleLoginUser,
  validateToken,
  logout,
  clearError
} from 'store/slices/auth'
import type { LoginRequest, RegisterRequest } from 'types/api'

export const useAuth = () => {
  const dispatch = useAppDispatch()
  const { user, isAuthenticated, token, expiresIn, loading, error } =
    useAppSelector((state) => state.auth)

  useEffect(() => {
    if (token) {
      dispatch(validateToken(token))
    }
  }, [dispatch, token])

  const login = useCallback(
    async (credentials: LoginRequest) => {
      const result = await dispatch(loginUser(credentials)).unwrap()
      return result
    },
    [dispatch]
  )

  const googleLogin = useCallback(
    async (token: string) => {
      const result = await dispatch(googleLoginUser(token)).unwrap()
      return result
    },
    [dispatch]
  )

  const register = useCallback(
    async (userData: RegisterRequest) => {
      const result = await dispatch(registerUser(userData)).unwrap()
      return result
    },
    [dispatch]
  )

  const signOut = useCallback(() => {
    dispatch(logout())
  }, [dispatch])

  const clearAuthError = useCallback(() => {
    dispatch(clearError())
  }, [dispatch])

  return {
    user,
    token,
    expiresIn,
    isAuthenticated,
    loading,
    error,
    login,
    googleLogin,
    register,
    signOut,
    clearAuthError
  }
}
