import { useEffect, useState } from 'react'
import { QRCodeSVG } from 'qrcode.react'
import Logo from 'assets/pngs/login-logo.png'
import LogoMain from 'assets/pngs/logo.png'

interface DesktopBlockerProps {
  children: React.ReactNode
}

const DesktopBlocker = ({ children }: DesktopBlockerProps) => {
  const [isMobile, setIsMobile] = useState(true)
  const [currentUrl, setCurrentUrl] = useState('')

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor
      const isMobileDevice =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          userAgent
        )
      setIsMobile(isMobileDevice)
    }

    const getCurrentUrl = () => {
      setCurrentUrl(window.location.href)
    }

    checkMobile()
    getCurrentUrl()

    window.addEventListener('resize', checkMobile)
    return () => window.removeEventListener('resize', checkMobile)
  }, [])

  if (isMobile) {
    return <>{children}</>
  }

  return (
    <div className="min-h-screen bg-[#CC1E1E] flex items-center justify-center p-4">
      <div className="bg-white rounded-3xl w-full max-w-2xl p-8 flex flex-col items-center">
        {/* Logo */}
        <div className="w-full max-w-md mb-12 flex justify-center">
          <img src={LogoMain} alt="Logo" className="h-20" />
        </div>

        {/* QR Code with Snoonu logo background */}
        <div className="w-48 h-48 mb-8 relative bg-white p-2 rounded-lg shadow-md">
          <QRCodeSVG
            value={currentUrl}
            size={192}
            level="H"
            imageSettings={{
              src: Logo,
              x: undefined,
              y: undefined,
              height: 24,
              width: 24,
              excavate: true
            }}
          />
        </div>

        {/* Instructions */}
        <p className="text-center text-gray-800 text-lg mb-2">
          Scan the QR code on your mobile device to start game
        </p>
        <p className="text-[#CC1E1E] font-semibold text-xl mb-12">
          Snoonu Treasure Hunt
        </p>

        {/* Terms */}
        <p className="text-center text-sm text-gray-600">
          By Using This Snoonu Treasure Hunt you agree to our{' '}
          <a href="#" className="underline hover:text-[#CC1E1E]">
            Terms and Condition
          </a>{' '}
          and{' '}
          <a href="#" className="underline hover:text-[#CC1E1E]">
            Privacy Policy
          </a>
        </p>
      </div>
    </div>
  )
}

export default DesktopBlocker
