import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Wrapper from 'components/wrapper'
import Logo from 'assets/pngs/login-logo.png'
import Button from 'components/button'
import { motion } from 'framer-motion'
import { useAuth } from 'hooks/useAuth'
import { FiLogOut, FiCamera } from 'react-icons/fi'

import RewardList from 'components/RewardList'
import QRScanner from 'components/QRScanner'

const Start = () => {
  const navigate = useNavigate()
  const { signOut } = useAuth()
  const [showScanner, setShowScanner] = useState(false)
  const [refetch, setRefetch] = useState(false)

  const handleRefetch = () => {
    setRefetch(!refetch)
  }

  const handleLogout = () => {
    signOut()
    navigate('/login')
  }

  return (
    <>
      {showScanner && (
        <QRScanner
          onClose={() => {
            handleRefetch()
            setShowScanner(false)
          }}
        />
      )}
      <Wrapper position="start">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.5,
            ease: 'easeOut'
          }}
          className="w-full h-full flex flex-col"
        >
          {/* Header */}
          <div className="flex justify-between items-center px-4 py-3 bg-white shadow-sm fixed top-0 left-0 right-0 z-10">
            <img src={Logo} alt="Logo" className="h-10 w-auto" />
            <button
              onClick={handleLogout}
              className="p-2 text-gray-600 hover:text-[#C72B26] transition-colors"
            >
              <FiLogOut size={24} />
            </button>
          </div>

          {/* Main Content */}
          <div className="flex-1 mt-16 px-4 pb-20">
            <RewardList refresh={refetch} />
          </div>

          {/* Fixed Bottom Action Button */}
          <div className="fixed bottom-6 left-0 right-0 px-4">
            <Button
              withShadow
              variant="primary"
              onClick={() => setShowScanner(true)}
              title="Scan QR Code"
              icon={<FiCamera className="text-xl" />}
            />
          </div>
        </motion.div>
      </Wrapper>
    </>
  )
}

export default Start
